<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card
        title="Ürün Ekle"
      >

        <project-add-line-form />
      </b-card>
    </validation-observer>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="dataItem.submitData"
          @click="submitData"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProjectAddLineForm from '@/views/Admin/Projects/Components/AddLinesForm.vue'

export default {
  name: 'ProjectProductAdd',
  components: {
    ProjectAddLineForm,
    BCol,
    BCard,
    BRow,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      id_products: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projectLines/dataItem']
    },
    saveData() {
      return this.$store.getters['projectLines/dataSaveStatus']
    },

  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push(`/app/projects/view/${this.$route.params.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('projectLines/RESET_DATA_ITEM')
  },
  methods: {
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_projects = this.$route.params.id
          this.dataItem.id_units = 1
          this.$store.dispatch('projectLines/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
